import React, {FC, useContext, useEffect, useState} from "react"
import "./OrderSinglePage.scss"
import {Link, useNavigate, useParams} from "react-router-dom"
import ky from "ky"
import {HAPI_URL} from "../_helpers/environment"
import {encodeBase64} from "../_helpers/encoding"
import {TypeOrder} from "../_types/types"
import {AuthStateContext} from "../_context/AuthContext"
import Loader from "../_components/Loader/Loader"
import CartModule from "../_components/Cart/CartModule"
import {CartStateContext} from "../_context/CartContext"
import {AppStateContext} from "../_context/AppContext"

const OrderSinglePage: FC = () => {
    const {orderNumber} = useParams()
    const {key, user} = useContext(AuthStateContext)
    const [order, setOrder] = useState<TypeOrder>()
    const [busy, setBusy] = useState<boolean>(true)
    const [error, setError] = useState<any>()
    const {empty, addItem} = useContext(CartStateContext)
    const {showOverlay} = useContext(AppStateContext)
    const navigate = useNavigate()
    useEffect(() => {
        ky.get(`${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/orders/${orderNumber}`,
            {"headers": {"Authorization": "Bearer " + key}})
            .json<TypeOrder>()
            .then(resp => setOrder(resp))
            .catch(err => setError(err))
            .finally(() => setBusy(false))
    }, [])


    if (busy || !order)
        return <Loader/>

    if (error)
        return <div className="toast toast-error">{error.toString()}</div>

    return (
        <div id="orderPage">
            <h1>Order #{orderNumber}</h1>
            <div className="section-title">Address</div>
            <div className="address-section">
                <div className="address-title">{order.customer.address.name}</div>
                <div>{order.customer.name} {order.customer.vatID ? `(VAT: ${order.customer.vatID})` : null}</div>
                <div>{order.customer.email}</div>
                <div>{order.customer.telephone}</div>
                <div>{order.customer.address.streetAddress}, {order.customer.address.postalCode} {order.customer.address.addressLocality}, {order.customer.address.addressCountry}</div>
            </div>
            {order.description ? (
                <>
                    <div className="section-title">Delivery Instructions</div>
                    <div className="address-section">{order.description}</div>
                </>
            ) : null}
            <div className="section-title">Order Sheet</div>
            <CartModule
                className="order-cart always-visible"
                noCartButton={true}
                items={order.orderedItem.map(e => ({
                    "quantity": e.orderQuantity,
                    "product": e.orderedItem,
                    "name": e.name,
                }))}
                hideTitle={true}
                fullWidth={true}
            />
            <div className="text-center mt-2">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        empty()
                        order?.orderedItem.forEach(item => {
                            addItem(item.orderedItem["@id"], item.name, item.orderQuantity)
                        })
                        showOverlay(2)
                        navigate("/checkout")
                    }}
                >Re-order
                </button>
                <Link to="/orders" className="btn btn-secondary ml-2">Return to orders</Link>
            </div>
        </div>
    )
}

export default OrderSinglePage