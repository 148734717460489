import React, {FC} from "react"
import "./PreferencesPage.scss"

const PreferencesPage: FC = () => {
    return (
        <div>
            Not implemented.
        </div>
    )
}

export default PreferencesPage