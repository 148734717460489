import {TypeCart} from "../_types/types"


export const getCart = (): TypeCart => {
    const cartData = localStorage.getItem("persistent.cart")
    if (cartData === null) {
        return {"items": []}
    }
    try {
        return JSON.parse(cartData)
    } catch (e) {
        console.warn("could not read cart data")
        localStorage.removeItem("persistent.cart")
        return {"items": []}
    }
}

export const storeCart = (cart: TypeCart): TypeCart => {
    cart.items.sort((a, b) => {
        return a.name < b.name ? -1 : 1
    })
    localStorage.setItem("persistent.cart", JSON.stringify(cart))
    return cart
}

export const addToCart = (id: string, name: string, qty: number): TypeCart => {
    const cart = getCart()
    let exists = false
    for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].product["@id"] === id) {
            cart.items[i].quantity += qty
            if (cart.items[i].quantity <= 0) {
                cart.items.splice(i, 1)
            }
            exists = true
            break
        }
    }
    if (!exists) {
        cart.items.push({
            "product": {
                "@id": id,
            },
            "name": name,
            "quantity": qty,
        })
    }
    return storeCart(cart)
}

export const removeFromCart = (id: string): TypeCart => {
    const cart = getCart()
    for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].product["@id"] === id) {
            cart.items.splice(i, 1)
            break
        }
    }
    return storeCart(cart)
}