import React, {FC, useContext} from "react"
import {useTranslation} from "react-i18next"
import "./Header.scss"
import {AuthStateContext} from "../../_context/AuthContext"
import {AppStateContext} from "../../_context/AppContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars, faRightFromBracket} from "@fortawesome/free-solid-svg-icons"
import SearchBar from "../Search/SearchBar"

const Header: FC = () => {
    const {hideOverlay, showOverlay, overlayId} = useContext(AppStateContext)
    const {signOut, user, key} = useContext(AuthStateContext)
    const {i18n} = useTranslation()
    return (
        <header className="navbar" id="mainHeader">
            <section className="navbar-section">
                <button
                    type="button"
                    className="btn btn-primary btn-wine-list"
                    onClick={() => overlayId === 1 ? hideOverlay() : showOverlay(1)}
                >
                    <FontAwesomeIcon icon={faBars} className="mr-2" fontSize={13}/>
                    {overlayId === 1 ? "Close" : "Wine List"}
                </button>
            </section>
            <section className="navbar-section">
                <SearchBar/>
            </section>
            <section className="navbar-section">
                {user ? (
                    <div className="nav-user">
                        {user.name}
                    </div>
                ) : null}
                <button
                    type="button"
                    className="btn btn-secondary btn-logout"
                    onClick={() => signOut()}
                >Sign Out
                    <FontAwesomeIcon icon={faRightFromBracket} className="ml-2" fontSize={13}/>
                </button>
            </section>
        </header>
    )
}

export default Header