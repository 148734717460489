import React, {createContext, FC, useState} from "react"
import {TypeCart} from "../_types/types"
import {addToCart, getCart, removeFromCart, storeCart} from "../_helpers/cart"


export interface CartState {
    addItem: (id: string, name: string, qty: number) => void
    removeItem: (id: string) => void
    empty: () => void
    cart: TypeCart
}

export const CartStateContext = createContext<CartState>({
    "addItem": () => {
    },
    "removeItem": () => {
    },
    "empty": () => {
    },
    "cart": {"items": []},
})

interface CartWrapperProps {
    children?: React.ReactNode
}

export const CartWrapper: FC<CartWrapperProps> = ({children}) => {

    const [state, setState] = useState<CartState>({
        addItem(id: string, name: string, qty: number) {
            setState(s => ({...s, "cart": addToCart(id, name, qty)}))
        },
        empty() {
            setState(s => ({...s, "cart": storeCart({"items": []})}))
        },
        removeItem(id: string) {
            setState(s => ({...s, "cart": removeFromCart(id)}))
        },
        "cart": getCart(),
    })

    return (
        <CartStateContext.Provider value={state}>
            {children}
        </CartStateContext.Provider>
    )
}