import React, {createContext, FC, useEffect, useState} from "react"
import ky from "ky"
import {HAPI_URL} from "../_helpers/environment"
import jwtDecode from "jwt-decode"

export interface AuthState {
    isAuthenticated: boolean
    isLoading: boolean
    key: string | null
    signIn: (country: string, telephone: string, pin: string) => void
    signOut: () => void
    error: any
    user?: {
        uid: string
        name: string
        email: string
        phone: string
        country: string
    }
}

export const AuthStateContext = createContext<AuthState>({
    "isAuthenticated": false,
    "isLoading": false,
    "key": null,
    "error": null,
    "signIn": () => {
    },
    "signOut": () => {
    },
})

interface AuthWrapperProps {
    children?: React.ReactNode
}

export const AuthWrapper: FC<AuthWrapperProps> = ({children}) => {

    const [state, setState] = useState<AuthState>({
        "isAuthenticated": true,
        "isLoading": true,
        "error": null,
        "key": null,
        "signIn": (country: string, telephone: string, pin: string) => {
            console.log(`sign in with ${country}${telephone}:${pin}`)
            setState(p => ({...p, "isLoading": true, "isAuthenticated": false}))
            ky.post(`${HAPI_URL}/auth/sign-in`, {"json": {pin, telephone, country}}).json<{ token: string }>()
                .then(({token}) => {
                    localStorage.setItem("session.key", token)
                    const payload = jwtDecode<any>(token)
                    const user = {
                        "uid": payload.sub,
                        "name": payload.name,
                        "email": payload.email,
                        "phone": payload.telephone,
                        "country": payload.country,
                    }
                    setState(p => ({...p, "isAuthenticated": true, "error": null, "key": token, "user": user}))
                })
                .catch(err => setState(p => ({...p, "isAuthenticated": false, "error": err})))
                .finally(() => setState(p => ({...p, "isLoading": false})))
        },
        "signOut": () => {
            localStorage.removeItem("session.key")
            setState(p => ({...p, "isAuthenticated": false, "user": undefined}))
        },
    })

    useEffect(() => {
        const key = localStorage.getItem("session.key")
        if (key === null) {
            setState(p => ({...p, "isAuthenticated": false, "isLoading": false, "key": null}))
        } else {
            ky.get(`${HAPI_URL}/auth/verify`, {"headers": {"Authorization": "Bearer " + key}})
                .then(() => {
                    const payload = jwtDecode<any>(key)
                    const user = {
                        "uid": payload.sub,
                        "name": payload.name,
                        "email": payload.email,
                        "phone": payload.telephone,
                        "country": payload.country,
                    }
                    setState(p => ({...p, "isAuthenticated": true, "key": key, "user": user}))
                })
                .catch(err => {
                    localStorage.removeItem("session.key")
                    setState(p => ({...p, "isAuthenticated": false, "key": null, "error": err}))
                })
                .finally(() => setState(p => ({...p, "isLoading": false})))
        }
    }, [])

    return (
        <AuthStateContext.Provider value={state}>
            {children}
        </AuthStateContext.Provider>
    )
}