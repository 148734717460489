export const searchStringToRegex = (input: string) => {
    input = input.toLowerCase().replace(/[^a-z0-9]/g, ".")
    let token = ""
    for (let i = 0; i < input.length; i++) {
        token += "(" + input[i] + "|_)"
    }
    return new RegExp(token, "g")
}

export const normalize = (s: string): string => {
    if (typeof s.normalize !== "undefined")
        return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    else
        return s
}

export const searchStringFilter = (input: string) => {
    return input.toLowerCase().replace(/[^a-z0-9]/g, "_")
}