import React, {FC, useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import FormInput from "../Input/FormInput"
import FormSelectCountry from "../Input/FormSelectCountry"
import {TypeOrganization} from "../../_types/types"
import {AuthStateContext} from "../../_context/AuthContext"
import ky from "ky"
import {HAPI_URL} from "../../_helpers/environment"
import {encodeBase64} from "../../_helpers/encoding"

interface Props {
    addressId: number
    onSubmit: () => void
}

const AddressForm: FC<Props> = React.memo(({addressId, onSubmit}) => {
    const {key, user} = useContext(AuthStateContext)
    const [address, setAddress] = useState<TypeOrganization>({
        "@id": "",
        "@type": "Organization",
        "telephone": `0${user?.phone}` || "",
        "name": user?.name || "",
        "vatID": "",
        "address": {
            "@type": "PostalAddress",
            "name": "",
            "streetAddress": "",
            "addressLocality": "",
            "postalCode": "",
            "addressCountry": "BE",
        },
    })
    const [streetAddress, setStreetAddress] = useState<string>("")
    const [streetAddressNumber, setStreetAddressNumber] = useState<string>("")

    useEffect(() => {
        setAddress(p => ({
            ...p, "address": {...p.address, "streetAddress": streetAddress + "   " + streetAddressNumber},
        }))
    }, [streetAddress, streetAddressNumber])

    useEffect(() => {
        ky.get(
            `${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/addresses/${addressId}`,
            {"headers": {"Authorization": "Bearer " + key}}).json<TypeOrganization>()
            .then(res => {
                setAddress(res)
                const addressParts = res.address.streetAddress.split("   ")
                setStreetAddress(addressParts[0])
                setStreetAddressNumber(addressParts.length < 2 ? "" : addressParts[1])
            })
            .catch(() => {
            })
    }, [])
    const [busy, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()

    const {t} = useTranslation("form")
    return (
        <form
            className="card-unit card-mb checkout-billing-form"
            onSubmit={e => {
                e.preventDefault()
                ky.put(
                    `${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/addresses/${addressId}`,
                    {"headers": {"Authorization": "Bearer " + key}, "json": address})
                    .then(onSubmit)
                    .catch(err => {
                        setError(err)
                        setBusy(false)
                    })
            }}
        >
            <FormInput
                id={"billingName"}
                placeholder={t("placeholder/name", "")}
                label={t("label/restaurantName", {"ns": "common"})}
                required={true}
                type={"text"}
                value={address.address.name}
                onChange={v => setAddress(prev => ({...prev, "address": {...prev.address, "name": v}}))}
            />
            <FormInput
                id={"billingCompany"}
                placeholder={t("placeholder/company", "")}
                label={t("label/company")}
                required={true}
                type={"text"}
                value={address.name}
                onChange={v => setAddress(prev => ({...prev, "name": v}))}
            />
            <FormInput
                id={"billingVAT"}
                placeholder={t("placeholder/vat", "")}
                label={t("label/vat")}
                required={false}
                type={"text"}
                value={address.vatID}
                onChange={v => setAddress(prev => ({...prev, "vatID": v}))}
            />

            <div className="columns">
                <div className="column col-9 col-md-12">
                    <FormInput
                        id={"billingStreet"}
                        label={t("label/street")}
                        placeholder={t("placeholder/streetAddress", "")}
                        type={"text"}
                        value={streetAddress}
                        onChange={v => setStreetAddress(v)}
                        required={true}
                    />
                </div>
                <div className="column col-3 col-md-6">
                    <FormInput
                        id={"billingStreetNumber"}
                        label={t("label/streetNumber", "Number")}
                        placeholder={t("placeholder/streetNumber", "Number")}
                        type={"text"}
                        value={streetAddressNumber}
                        onChange={v => setStreetAddressNumber(v)}
                        required={true}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6 col-md-12">
                    <FormInput
                        id={"billingCity"}
                        label={t("label/city")}
                        type={"text"}
                        placeholder={t("placeholder/addressLocality", "")}
                        value={address.address.addressLocality}
                        onChange={v => setAddress(prev => ({
                            ...prev,
                            "address": {...prev.address, "addressLocality": v},
                        }))}
                        required={true}
                    />
                </div>
                <div className="column col-6 col-md-12">
                    <FormInput
                        id={"billingPostalCode"}
                        label={t("label/postalCode")}
                        type={"text"}
                        placeholder={t("placeholder/addressLocality", "")}
                        value={address.address.postalCode}
                        onChange={v => setAddress(prev => ({...prev, "address": {...prev.address, "postalCode": v}}))}
                        required={true}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6 col-md-12">
                    <FormInput
                        id={"billingTelephone"}
                        label={t("label/telephone")}
                        placeholder={t("placeholder/telephone", "")}
                        type={"text"}
                        value={address.telephone}
                        onChange={v => setAddress(prev => ({...prev, "telephone": v}))}
                        required={true}
                    />
                </div>
                <div className="column col-6 col-md-12">
                    <FormSelectCountry
                        id={"billingCountry"}
                        label={t("label/country")}
                        value={address.address.addressCountry}
                        onChange={v => setAddress(prev => ({
                            ...prev,
                            "address": {...prev.address, "addressCountry": v},
                        }))}
                        required={true}
                    />
                </div>
            </div>
            {busy ? (
                <div className="fa-spin">
                </div>
            ) : (
                <div style={{"marginTop": "20px"}}>
                    <button className="btn btn-primary mr-2" type="submit">Save Address</button>
                    <button className="btn btn-secondary" type="button" onClick={() => onSubmit()}>Discard</button>
                </div>
            )}
            {error ? (
                <div className="toast toast-error mt-2">Failed to save address</div>
            ) : null}
        </form>
    )
})

export default AddressForm