import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React, {FC, useContext, useEffect, useState} from "react"
import "./LoginPage.scss"
import {faLock} from "@fortawesome/free-solid-svg-icons"
import {AuthStateContext} from "../_context/AuthContext"
import {continents, countries} from "country-data-list"

const ErrorModal: FC<{ error: any }> = ({error}) => {

    let message = "Unknown error has occurred"
    if (typeof error === "object") {
        if (typeof error.response === "object") {
            const req = error.response
            console.log(req.status)
            if (req.status === 400) {
                message = "Telephone and pin combination was invalid."
            } else {
                message = "Session has expired. Please sign in again."
            }
        } else if (typeof error.toString === "function") {
            message = error.toString()
        }
    }

    return (
        <div className="toast toast-error">{message}</div>
    )
}

const LoginPage: FC = () => {

    const [pinCode, setPinCode] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [countryCode, setCountryCode] = useState<string>("BE")
    const {signIn, error} = useContext(AuthStateContext)

    useEffect(() => {
        const storedPhone = localStorage.getItem("form.telephone")
        if (storedPhone !== null)
            setPhone(storedPhone)
        const storedCountry = localStorage.getItem("form.country")
        if (storedCountry !== null)
            setCountryCode(storedCountry)
    }, [])

    useEffect(() => {
        localStorage.setItem("form.telephone", phone)
    }, [phone])

    useEffect(() => {
        localStorage.setItem("form.country", countryCode)
    }, [countryCode])

    let countryCodes = continents.europe.countries
    // @ts-ignore
    let prefixes = countryCodes.map((code: string) => countries[code])
    prefixes = prefixes.filter((c: any) => c.countryCallingCodes.length === 1)
    prefixes = prefixes.filter((c: any) => c.countryCallingCodes[0].indexOf(" ") === -1)
    prefixes = prefixes.sort((a: any, b: any) => {
        const p1 = parseInt(a.countryCallingCodes[0].substring(1))
        const p2 = parseInt(b.countryCallingCodes[0].substring(1))
        return p1 - p2
    })

    return (
        <div className="columns" id="authentication">
            <div className="column col-md-12 col-lg-8 col-xl-6 col-4">
                <div className="sign-in-header">
                    <div className="company-logo"/>
                    <div className="company-name">Matthys Wines</div>
                </div>
                <div className="auth-container">
                    <form className="auth-body" onSubmit={e => {
                        e.preventDefault()
                        let p = phone
                        p = p.replace(" ", "")
                        p = p.replace("-", "")
                        p = p.replace("/", "")
                        signIn(countryCode, p, pinCode)
                    }}>
                        <div className="text-center horeca-title">
                            <div>Horeca</div>
                            <div>Order Form</div>
                        </div>
                        <div className="form-group mb-2">
                            <label className="form-label">Phone</label>
                            <div className="input-group">
                                <select value={countryCode} onChange={e => {
                                    const v = e.target.value
                                    setCountryCode(v)
                                }} className="form-select" style={{"maxWidth": "80px"}}>
                                    {prefixes.map((country: any) => (
                                        <option key={country.alpha3} value={country.alpha2}>
                                            {country.countryCallingCodes[0]}
                                        </option>
                                    ))}
                                </select>
                                <input onChange={e => {
                                    const v = e.target.value
                                    setPhone(v)
                                }} required={true} value={phone} inputMode="numeric" type="text"
                                       className="form-input"/>
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label>Pin Code</label>
                            <input onChange={e => {
                                const v = e.target.value
                                setPinCode(v)
                            }} required={true} value={pinCode} type="text" maxLength={4} inputMode="numeric"
                                   className="form-input pin-input"
                                   placeholder="____"/>
                        </div>
                        {error !== null ? (
                            <ErrorModal error={error}/>
                        ) : null}
                        <div className="text-center" style={{"marginTop": "32px", "marginBottom": "32px"}}>
                            <button className="btn btn-primary" type="submit">
                                Confirm
                                <FontAwesomeIcon className={"ml-2"} icon={faLock}/>
                            </button>
                        </div>
                    </form>
                </div>
                <footer className="sign-in-footer">
                    <div className="checkout-copyright">
                        <div className="copyright-text">© {(new Date()).getFullYear()} Matthys Wijnimport nv</div>
                        <div>
                            <a className="text-red" target="_blank" href={"https://www.matthys-wines.com/en/articles/disclaimer"}>Terms</a>
                            <a className="text-red" target="_blank" href={"https://www.matthys-wines.com/en/articles/privacy"}>Privacy</a>
                        </div>
                    </div>
                </footer>
            </div>
            <div className="column hide-md col-lg-4 col-xl-6 col-8 page-image"/>
        </div>
    )
}

export default LoginPage
