import React, {FC} from "react"
import "./CheckoutPage.scss"
import AddressForm from "../_components/Form/AddressForm"
import {useTranslation} from "react-i18next"
import CartModule from "../_components/Cart/CartModule"
import {useNavigate, useParams} from "react-router-dom"

const CheckoutPage: FC = () => {
    const {t} = useTranslation("form")
    const {index} = useParams<{ index: string }>()
    const navigate = useNavigate()
    if (!index)
        return null
    return (
        <div id="checkoutPage">
            <div className="checkout-container">
                <div className="page-title">
                    Address
                </div>
                <AddressForm onSubmit={() => navigate("/checkout")} addressId={parseInt(index)}/>
            </div>
            <CartModule noCartButton={true}/>
        </div>
    )
}

export default CheckoutPage