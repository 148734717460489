import React, {createContext, FC, useState} from "react"

export interface AppState {
    showOverlay: (id: number) => void
    hideOverlay: () => void
    overlayId: number
    setSearchText: (s: string) => void
    searchText: string
}

export const AppStateContext = createContext<AppState>({
    hideOverlay(): void {
    },
    "overlayId": 0,
    showOverlay(id: number): void {
    },
    "searchText": "",
    "setSearchText": (s: string) => void {},
})

interface AppStateWrapperProps {
    children?: React.ReactNode
}

export const AppStateWrapper: FC<AppStateWrapperProps> = ({children}) => {

    const [state, setState] = useState<AppState>({
        hideOverlay(): void {
            state.showOverlay(0)
        },
        "overlayId": 0,
        showOverlay(id: number): void {
            setState(p => ({...p, "overlayId": id}))
        },
        "searchText": "",
        "setSearchText": (s: string) => {
            setState(p => ({...p, "searchText": s}))
        },
    })

    return (
        <AppStateContext.Provider value={state}>
            {children}
        </AppStateContext.Provider>
    )
}