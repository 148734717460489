import React, {FC, useContext, useEffect, useState} from "react"
import "./SearchBar.scss"
import {Link} from "react-router-dom"
import {WineCatalogEntry} from "../../_types/types"
import ky from "ky"
import {CATALOG_URL} from "../../_helpers/environment"
import {AppStateContext} from "../../_context/AppContext"

let fetchTimeout: any = -1

interface SearchBarResultEntryProps {
    wine: WineCatalogEntry
}

const SearchBarResultEntry: FC<SearchBarResultEntryProps> = ({wine}) => {
    const {setSearchText} = useContext(AppStateContext)
    return (
        <tr>
            <td>
                <Link onClick={() => {
                    setSearchText(wine.name)
                }} to={`/catalog/${wine.country}#${wine.uid}`}>{wine.name}</Link>
            </td>
        </tr>
    )
}

let showResultsTimeout: any = undefined

const SearchBar: FC = () => {

    const [query, setQuery] = useState<string>("")
    const [results, setResults] = useState<WineCatalogEntry[]>([])
    const [focused, setFocused] = useState<boolean>(false)
    const [hover, setHover] = useState<boolean>(false)
    const [showResults, setShowResults] = useState<boolean>(false)

    useEffect(() => {
        setResults([])
        clearTimeout(fetchTimeout)
        if (query.length < 2)
            return
        fetchTimeout = setTimeout(() => {
            ky.get(`${CATALOG_URL}/query/wines?find=${query}`).json<{ "wines": WineCatalogEntry[] }>()
                .then(res => setResults(res.wines.sort((a: WineCatalogEntry, b: WineCatalogEntry) => {
                    return a.name < b.name ? -1 : 1
                })))
                .catch(err => console.log(err))
        }, 250)
        return () => {
            clearTimeout(fetchTimeout)
        }
    }, [query])

    useEffect(() => {
        if (hover || focused) {
            clearTimeout(showResultsTimeout)
            setShowResults(true)
        } else {
            setTimeout(() => {
                setShowResults(false)
            }, 200)
        }
    }, [hover, focused])

    return (
        <div
            id="searchBar"
            className="search-container"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="search-input-group">
                <input
                    placeholder={"Search..."}
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    className="form-input search-input"
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                />
            </div>
            {(results && showResults) ? (
                <div className="search-results">
                    <table>
                        <tbody>
                        {results.map(wine => (
                            <SearchBarResultEntry key={wine.uid} wine={wine}/>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : null}
        </div>
    )
}

export default SearchBar