import {useEffect, useState} from "react"
import ky from "ky"
import {CATALOG_URL} from "../_helpers/environment"
import {CatalogFilterTree} from "../_types/types"

interface CatalogHookType {
    error: any
    busy: boolean
    catalog: CatalogFilterTree
}

export const useCatalog = (): CatalogHookType => {
    const [error, setError] = useState<any>(undefined)
    const [busy, setBusy] = useState<boolean>(true)
    const [catalog, setCatalog] = useState<CatalogFilterTree>({} as CatalogFilterTree)
    useEffect(() => {
        setBusy(true)
        ky.get(`${CATALOG_URL}/filter/wines`)
            .json<CatalogFilterTree>()
            .then(res => setCatalog(res))
            .catch(err => setError(err))
            .finally(() => {
                setBusy(false)
            })
    }, [])
    return {error, busy, catalog}
}