import React, {FC, useContext} from "react"
import Loader from "../_components/Loader/Loader"
import LoginPage from "./LoginPage"
import {AuthStateContext} from "../_context/AuthContext"

interface PrivateRouteProps {
    title?: string
    children?: React.ReactNode
}

const PrivateRoute: FC<PrivateRouteProps> = ({children, title}) => {
    const {isAuthenticated, isLoading} = useContext(AuthStateContext)
    if (isLoading) {
        return <Loader/>
    } else if (isAuthenticated) {
        return <>{children}</>
    } else {
        return (
            <LoginPage/>
        )
    }
}

export default PrivateRoute
