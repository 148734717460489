import React, {Suspense} from "react"
import ReactDOM from "react-dom/client"
import {BrowserRouter as Router} from "react-router-dom"
import App from "./App"
import "./i18n"
import "spectre.css/dist/spectre.min.css"
import PrivateRoute from "./Authentication/PrivateRoute"
import {AuthWrapper} from "./_context/AuthContext"
import Loader from "./_components/Loader/Loader"


const container = document.getElementById("root") as HTMLElement
const root = ReactDOM.createRoot(container)
root.render((
    <Suspense fallback={<Loader/>}>
        <Router>
            <AuthWrapper>
                <PrivateRoute>
                    <App/>
                </PrivateRoute>
            </AuthWrapper>
        </Router>
    </Suspense>
))
