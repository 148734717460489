import React, {FC} from "react"
import "./Loader.scss"

const Loader: FC = () => (
    <div className="loader-page">
        <div className="loader-breathing darker"/>
    </div>
)

export default Loader
