import React, {FunctionComponent} from "react"
import {getCode, getNames} from "country-list"
import {normalize} from "../../_helpers/search"

const countriesSorted = [...getNames()]
countriesSorted.sort((a, b) => {
    return (normalize(a.toLowerCase()) < normalize(b.toLowerCase())) ? -1 : 1
})

interface Props {
    id: string
    label?: string
    value: string
    onChange: (value: string) => void
    hint?: string
    success?: boolean
    invalid?: boolean
    required?: boolean
    className?: string
}

const FormSelectCountry: FunctionComponent<Props> = props => (
    <div className={`form-group ${props.success ? "has-success" : ""} ${props.invalid ? "has-error" : ""}`}>
        {props.label ? <label className="form-label" htmlFor={props.id}>{props.label}{props.required ? "*" : ""}</label> : null}
        <select
            id={props.id}
            value={props.value}
            required={props.required}
            className={"form-select " + (props.className ? (" " + props.className) : "")}
            onChange={e => props.onChange(e.target.value)}
        >
            <option value={""}> - Select a country -</option>
            {countriesSorted.map((name) => (
                <option
                    key={name}
                    value={getCode(name)}
                >{name}</option>
            ))}
        </select>
        {props.hint ? <p className="form-input-hint mb-0">{props.hint}</p> : null}
    </div>
)

export default FormSelectCountry