import React, {FC, useContext, useEffect, useState} from "react"
import "./CartModule.scss"
import {CartStateContext} from "../../_context/CartContext"
import {TypeCartItem, TypeWine} from "../../_types/types"
import ky from "ky"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faPencilAlt, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons"
import {Link} from "react-router-dom"
import {AppStateContext} from "../../_context/AppContext"
import {toHorecaPrice} from "../../_helpers/price"

interface EditQuantityModalProps {
    onClose: () => void
    name: string
    quantity: number
    onSave: (qty: number) => void
}

const EditQuantityModal: FC<EditQuantityModalProps> = ({onClose, name, quantity, onSave}) => {
    const [qty, setQty] = useState<string>(`${quantity}`)
    return (
        <form className="modal modal-sm active" id="editCartModal">
            <div className="modal-overlay" aria-label="Close" onClick={onClose}></div>
            <div className="modal-container">
                <div className="modal-header">
                    <button type="button" onClick={onClose} className="btn btn-clear float-right"
                            aria-label="Close"></button>
                    <div className="modal-title h5">Order quantity</div>
                </div>
                <div className="modal-body">
                    <div className="content">
                        Enter a new order quantity for <b>{name}</b>.
                        <input className="form-input mt-2" type="number" step={0} min={0} value={qty} onChange={e => {
                            const v = e.target.value
                            setQty(v)
                        }}/>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" onClick={() => {
                        onSave(isNaN(parseInt(qty)) ? 0 : parseInt(qty))
                    }}>Save
                    </button>
                    <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>Discard</button>
                </div>
            </div>
        </form>

    )
}

interface ProductEntryProps {
    item: TypeCartItem
    editable?: boolean
    hideStockStatus?: boolean
}

const ProductEntry: FC<ProductEntryProps> = ({item, editable, hideStockStatus}) => {

    const cart = useContext(CartStateContext)
    const [wine, setWine] = useState<TypeWine>()
    const [error, setError] = useState<any>()
    const [busy, setBusy] = useState<boolean>(true)
    const [editQuantity, setEditQuantity] = useState<boolean>(false)

    useEffect(() => {

        ky.get(item.product["@id"]).json<TypeWine>()
            .then(res => setWine(res))
            .catch(err => setError(err))
            .finally(() => setBusy(false))
    }, [item.product["@id"]])

    if (busy || wine === undefined) {
        return (
            <tr>
                <td>...</td>
            </tr>
        )
    }

    if (error)
        return null // todo handle this better

    return (
        <tr className="cart-entry">
            <td className="cart-item-img hide-md">
                {wine.image?.thumbnail ? (
                    <img alt={"?"} width={16} src={wine.image.thumbnail.contentUrl}/>
                ) : <div className="no-image">?</div>}
            </td>
            <td className="cart-item-name">
                <div className="wine-name">{wine.name}</div>
                {hideStockStatus ? null : (
                    wine.offers.availability === "in-stock" ? (
                        <div className={"wine-stock label-in-stock"}>
                            <FontAwesomeIcon className={"mr-1"} icon={faCheck}/>
                            In Stock
                        </div>
                    ) : (
                        <div className={"wine-stock label-out-of-stock"}>
                            <FontAwesomeIcon className={"mr-1"} icon={faTimes}/>
                            Out of Stock
                        </div>
                    )
                )}
            </td>
            <td className="cart-item-quantity text-right">{item.quantity}x</td>
            <td className="cart-item-price text-right hide-md">
                &euro;{toHorecaPrice(wine.offers.integerPrice / 100).toFixed(2)}
            </td>
            {editable ? (
                <>
                    <td className="cart-item-btn">
                        <button className="btn btn-edit" type="button" onClick={() => {
                            setEditQuantity(true)
                        }}><FontAwesomeIcon icon={faPencilAlt}/></button>
                        {editQuantity ? (
                            <EditQuantityModal
                                onClose={() => setEditQuantity(false)}
                                onSave={(qty) => {
                                    setEditQuantity(false)
                                    cart.addItem(item.product["@id"], wine.name, qty - item.quantity)
                                }}
                                name={wine.name}
                                quantity={item.quantity}
                            />
                        ) : null}
                    </td>
                    <td className="cart-item-btn">
                        <button className="btn btn-remove" type="button" onClick={() => {
                            cart.removeItem(item.product["@id"])
                        }}><FontAwesomeIcon icon={faTrash}/></button>
                    </td>
                </>
            ) : null}
        </tr>
    )
}

interface EditableCartProps {
    editable?: boolean
    hasCheckout?: boolean
    hideTitle?: boolean
    fullWidth?: boolean
    className?: string
    noCartButton?: boolean
    hideStockStatus?: boolean
    items?: TypeCartItem[]
}

const CartModule: FC<EditableCartProps> = props => {
    const {cart} = useContext(CartStateContext)
    const cartItems = props.items !== undefined ? props.items : cart.items
    const {hideOverlay, showOverlay, overlayId} = useContext(AppStateContext)
    return (
        <>
            {props.noCartButton ? null : (
                <>
                    <div className="mobile-cart-button-spacer"/>
                    <div className="mobile-cart-button">
                        {overlayId === 2 ? (
                            <button onClick={() => hideOverlay()}>Close</button>
                        ) : (
                            <button className="btn-cart-open" onClick={() => showOverlay(2)}>
                                Wine Cart
                                <div className="ml-2 label label-secondary">{cart.items.length}</div>
                            </button>
                        )}
                    </div>
                </>
            )}
            <div id="sideCart" className={`${props.className} ${overlayId === 2 ? "visible" : ""}`}>
                {!props.hideTitle ? <div className="text-center cart-title">Order Sheet</div> : null}
                <table className={"cart-table " + (props.fullWidth ? "full-width" : "")}>
                    {cartItems.length !== 0 ? (
                        <thead>
                        <tr className="cart-header">
                            <th className="hide-md"/>
                            <th>Name</th>
                            <th>Qty</th>
                            <th className="hide-md">PPU</th>
                        </tr>
                        </thead>
                    ) : null}
                    <tbody>
                    {cartItems.length === 0 ? (
                        <tr>
                            <td className="text-center">Your order sheet is empty</td>
                        </tr>
                    ) : cartItems.map(item => (
                        <ProductEntry
                            hideStockStatus={props.hideStockStatus}
                            editable={props.editable}
                            item={item}
                            key={item.product["@id"]}
                        />
                    ))}
                    </tbody>
                </table>
                {(cartItems.length !== 0 && props.hasCheckout) ? (
                    <div className="text-center mt-2">
                        <Link onClick={() => hideOverlay()} to="/checkout" className="btn btn-submit btn-primary">
                            Continue
                        </Link>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default CartModule