import React, {FC, ReactNode, useContext, useEffect, useState} from "react"
import "./NavigationPane.scss"
import {Link} from "react-router-dom"
import ky from "ky"
import {API_URL, CATALOG_URL} from "../../_helpers/environment"
import {CatalogFilterTree, TypeCountry} from "../../_types/types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faClock} from "@fortawesome/free-solid-svg-icons"
import {AppStateContext} from "../../_context/AppContext"


const CountryNavigation: FC = () => {

    const {hideOverlay} = useContext(AppStateContext)
    const [wineCatalog, setWineCatalog] = useState<CatalogFilterTree>({} as CatalogFilterTree)
    const [error, setError] = useState<any>(undefined)
    const [busy, setBusy] = useState<boolean>(false)

    const [countries, setCountries] = useState<TypeCountry[]>([])

    useEffect(() => {
        setBusy(true)
        ky.get(`${CATALOG_URL}/filter/wines`)
            .json<CatalogFilterTree>()
            .then(res => setWineCatalog(res))
            .catch(err => setError(err))
            .finally(() => {
                setBusy(false)
            })
    }, [])

    // note: this has a hard limit of 100 countries
    useEffect(() => {
        ky.get(`${API_URL}/documents/countries?page[size]=100`)
            .json<{ "@graph": TypeCountry[] }>()
            .then(res => setCountries(res["@graph"]))
    }, [])

    if (busy) return null
    if (error) return <div>error</div>

    const countryEntries: ReactNode[] = []

    const entries: { country: TypeCountry, count: number }[] = []

    for (let sid in wineCatalog.countries) {
        let country: TypeCountry | null = null
        for (let i = 0; i < countries.length; i++) {
            const id = countries[i]["@id"]
            if (id.substring(id.length - sid.length) === sid) {
                country = countries[i]
                break
            }
        }

        if (country === null)
            continue

        entries.push({country, "count": wineCatalog.countries[sid]})
    }

    entries.sort((a: any, b: any) => {
        return a.country.name < b.country.name ? -1 : 1
    })

    entries.forEach(entry => {
        countryEntries.push((
            <li key={entry.country.alternateName} className="menu-item">
                <Link onClick={() => hideOverlay()} to={"/catalog/" + entry.country["@id"].split("/").pop()}>
                    <span className="nav-icon"
                          style={{"backgroundImage": "url(\"/assets/countries/" + entry.country.identifier + ".svg\")"}}></span>
                    {entry.country.name}
                </Link>
                <div className="menu-badge">
                    <div className="label label-secondary">{entry.count}</div>
                </div>
            </li>
        ))
    })

    return (
        <>
            {countryEntries}
        </>
    )
}

const NavigationPane: FC = () => {
    const {hideOverlay, overlayId} = useContext(AppStateContext)
    return (
        <div id="navigationPane" className={overlayId === 1 ? "visible" : ""}>
            <div className="menu-spacer"></div>
            <ul className="menu">
                <li className="divider" data-content="ACCOUNT"/>
                <li className="menu-item">
                    <Link to="/orders" onClick={() => hideOverlay()}>My Orders</Link>
                </li>
                {/*<li className="menu-item">*/}
                {/*    <Link to="/settings">Settings</Link>*/}
                {/*</li>*/}
                <li className="divider" data-content="CATALOG"/>
                <li className="menu-item">
                    <Link to="/catalog/recent" onClick={() => hideOverlay()}>
                        <FontAwesomeIcon icon={faClock} style={{"color": "#939393", "marginRight": "10px"}}/>
                        Recently Ordered
                    </Link>
                </li>
                <CountryNavigation/>
            </ul>
        </div>
    )
}

export default NavigationPane