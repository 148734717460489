import React, {FC, useState} from "react"
import {Route, Routes} from "react-router-dom"
import "./App.scss"
import Header from "./_components/Header/Header"
import CatalogPage from "./Catalog/CatalogPage"
import PreferencesPage from "./Preferences/PreferencesPage"
import OrderListPage from "./Orders/OrderListPage"
import NavigationPane from "./_components/NavigationPane/NavigationPane"
import {CartWrapper} from "./_context/CartContext"
import CheckoutPage from "./Checkout/CheckoutPage"
import CheckoutAddressPage from "./Checkout/CheckoutAddressPage"
import OrderSinglePage from "./Orders/OrderSinglePage"
import {AppStateWrapper} from "./_context/AppContext"

const App: FC = () => {
    return (
        <CartWrapper>
            <AppStateWrapper>
                <div style={{"height": "53px"}}></div>
                <div className="page-container">
                    <NavigationPane/>
                    <div className='content-container'>
                        <Header/>
                        <Routes>
                            <Route path="/catalog/:id" element={<CatalogPage/>}/>
                            <Route path="/preferences" element={<PreferencesPage/>}/>
                            <Route path="/orders" element={<OrderListPage/>}/>
                            <Route path="/orders/:orderNumber" element={<OrderSinglePage/>}/>
                            <Route path="/checkout" element={<CheckoutPage/>}/>
                            <Route path="/checkout/address/:index" element={<CheckoutAddressPage/>}/>
                        </Routes>
                    </div>
                </div>
            </AppStateWrapper>
        </CartWrapper>
    )
}

export default App