import React, {FC, useCallback, useContext, useEffect, useState} from "react"
import CartModule from "../_components/Cart/CartModule"
import "./CheckoutPage.scss"
import FormInput from "../_components/Input/FormInput"
import {useTranslation} from "react-i18next"
import ky from "ky"
import {HAPI_URL} from "../_helpers/environment"
import {encodeBase64} from "../_helpers/encoding"
import {AuthStateContext} from "../_context/AuthContext"
import {TypeOrganization} from "../_types/types"
import {Link, useNavigate} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faPlus} from "@fortawesome/free-solid-svg-icons"
import {CartStateContext} from "../_context/CartContext"
import Loader from "../_components/Loader/Loader"

interface AddressEntryProps {
    address: TypeOrganization
    setActive: () => void
    active: boolean
    editLocation: string
}

const AddressEntry: FC<AddressEntryProps> = ({address, setActive, active, editLocation}) => (
    <button className={`address-entry${active ? " active" : ""}`} type="button" onClick={setActive}>
        <div className="address-body">
            <div className="address-title">
                {address.address.name}
            </div>
            <div className="address-summary">
                {address.name} {address.vatID ? `(VAT: ${address.vatID})` : null} {address.telephone}
                <br/>
                {address.address.streetAddress}, {address.address.postalCode} {address.address.addressLocality},
                {address.address.addressCountry}
            </div>
        </div>
        <div className="address-edit">
            <Link to={editLocation}>Edit</Link>
        </div>
        <div className="address-input">
            <div className="address-check">
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    </button>
)

interface AddressListProps {
    onChange: (address: TypeOrganization | null) => void
}

const AddressList: FC<AddressListProps> = ({onChange}) => {
    const {key, user} = useContext(AuthStateContext)
    const [addresses, setAddresses] = useState<TypeOrganization[]>([])
    const [busy, setBusy] = useState<boolean>(true)
    const [active, setActive] = useState<number>(-1)
    useEffect(() => {
        ky.get(`${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/addresses`,
            {"headers": {"Authorization": "Bearer " + key}})
            .json<{ addresses: TypeOrganization[] }>()
            .then(resp => setAddresses(resp.addresses))
            .catch(err => console.log(err))
            .finally(() => setBusy(false))
    }, [user])
    useEffect(() => {
        if (addresses.length === 1) {
            setActive(0)
        }
    }, [addresses])

    useEffect(() => {
        if (active < 0) {
            onChange(null)
        } else {
            onChange(addresses[active])
        }
    }, [active, addresses, onChange])

    if (busy)
        return null

    return (
        <>
            <div className="mb-2">Delivery Address</div>
            <div className="address-list">
                {addresses.map((addr, index) => (
                    <AddressEntry
                        editLocation={`/checkout/address/${addr.identifier}`}
                        setActive={() => setActive(index)}
                        active={index === active}
                        key={addr.identifier}
                        address={addr}
                    />
                ))}
                {addresses.length < 6 ? (
                    <Link className="address-entry" to={`/checkout/address/${addresses.length}`}>
                        <FontAwesomeIcon style={{"fontSize": "12px", "paddingTop": "4px"}} icon={faPlus}
                                         className="mr-2"/>
                        Add new address
                    </Link>
                ) : null}
            </div>
        </>
    )
}

const CheckoutPage: FC = () => {
    const {i18n} = useTranslation()
    const [notes, setNotes] = useState<string>("")
    const [address, setAddress] = useState<TypeOrganization | null>(null)
    const {cart, empty} = useContext(CartStateContext)
    const {user, key} = useContext(AuthStateContext)
    const [busy, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const navigate = useNavigate()

    const onSubmit = useCallback(() => {
        setBusy(true)
        setError(undefined)
        const payload = {
            "address": address,
            "language": i18n.language,
            "items": cart.items.map(cartItem => ({
                "orderQuantity": cartItem.quantity,
                "orderedItem": cartItem.product,
            })),
            "notes": notes,
        }
        ky.post(
            `${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/orders`,
            {
                "headers": {"Authorization": "Bearer " + key}, "json": payload,
            }).json<{ "orderNumber": number }>()
            .then(res => {
                empty()
                navigate(`/orders/${res.orderNumber}`)
            })
            .catch(err => setError(err))
            .finally(() => setBusy(false))
    }, [address, notes, i18n, cart, user, key, empty])

    const {t} = useTranslation("form")
    return (
        <div id="checkoutPage">
            {busy ? <Loader/> : null}
            <div className="checkout-container">
                <div className="page-title">
                    Confirm order
                </div>
                <AddressList onChange={setAddress}/>
                <FormInput
                    id={"deliveryInstructions"}
                    className="no-resize"
                    label={t("label/deliveryInstructions", "Delivery Instructions")}
                    placeholder={t("placeholder/deliveryInstructions", "Optional")}
                    type={"textarea"}
                    value={notes}
                    onChange={v => setNotes(v)}
                />
                <div className="text-center mt-2">
                    <button className="btn btn-primary btn-confirm" disabled={address === null} type="button"
                            onClick={onSubmit}>
                        Confirm order
                    </button>
                </div>
                {error ? (
                    <div className="toast toast-error mt-2">{error.toString()}</div>
                ) : null}
            </div>
            <CartModule editable={true}/>
        </div>
    )
}

export default CheckoutPage