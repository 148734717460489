import React, {FC, useContext, useEffect, useState} from "react"
import "./OrderListPage.scss"
import {AuthStateContext} from "../_context/AuthContext"
import {TypeOrder} from "../_types/types"
import {Link, useNavigate} from "react-router-dom"
import ky from "ky"
import {HAPI_URL} from "../_helpers/environment"
import {encodeBase64} from "../_helpers/encoding"
import Loader from "../_components/Loader/Loader"

const OrderListPage: FC = () => {
    const {key, user} = useContext(AuthStateContext)
    const [orders, setOrders] = useState<TypeOrder[]>([])
    const [busy, setBusy] = useState<boolean>(true)

    useEffect(() => {
        ky.get(`${HAPI_URL}/accounts/${encodeBase64(user?.email || "")}/orders`,
            {"headers": {"Authorization": "Bearer " + key}})
            .json<{ orders: TypeOrder[] }>()
            .then(resp => setOrders(resp.orders))
            .catch(err => console.log(err))
            .finally(() => setBusy(false))
    }, [])

    return (
        <div id="orderList">
            {busy ? <Loader/> : null}
            <h1>Orders</h1>
            <table>
                <thead>
                <tr>
                    <th>Order #</th>
                    <th>Date</th>
                    <th className="hide-md">Restaurant</th>
                    <th>Order</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {orders.length === 0 ? (
                    <tr>
                        <td>No orders</td>
                    </tr>
                ) : orders.map(order => (
                    <tr key={order.orderNumber}>
                        <td>{order.orderNumber}</td>
                        <td>
                            {order.orderDate.split("T")[0]}
                        </td>
                        <td className="hide-md">{order.customer.address.name}</td>
                        <td>{order.orderedItem.length} items</td>
                        <td><Link to={`/orders/${order.orderNumber}`}>View order</Link></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default OrderListPage