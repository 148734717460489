import i18next, {BackendModule} from "i18next"
import {initReactI18next} from "react-i18next"
import ky from "ky"
import {API_URL} from "./_helpers/environment"

interface ResourceBundle {
    [key: string]: string
}

const reportedKeys = new Set()

const backend: BackendModule = {
    "type": "backend",
    "init": function (services, backendOptions, i18nextOptions) {
    },
    "read": function (lng, ns, callback) {
        ky.get(`${API_URL}/localization/bundles/${ns}?language=${lng}`).json<ResourceBundle>()
            .then(bundle => {
                callback(null, bundle)
            })
            .catch(err => {
                // @ts-ignore
                callback(err, null)
            })
    },
    "save": function (language, namespace, data) {
    },
    "create": (lngs, ns, key) => {
        if (reportedKeys.has(`${ns}.${key}`)) return
        reportedKeys.add(`${ns}.${key}`)
        console.warn(`Missing translation '${key}' in '${ns}'`)
    },
}

const locationPathList = window.location.pathname.split("/")
export const LANGUAGES_EXISTING = ["en", "fr", "nl", "de", "es", "it"]

i18next
    .use(initReactI18next)
    .use(backend)
    .init({
        "supportedLngs": LANGUAGES_EXISTING,
        "ns": [
            "common",
            "error",
        ],
        "lng": (locationPathList.length >= 2 && LANGUAGES_EXISTING.includes(locationPathList[1])) ? locationPathList[1] : "en",
        "keySeparator": false,
        "interpolation": {
            "escapeValue": false,
        },
        "defaultNS": "common",
        backend,
        "saveMissing": true,
    })
